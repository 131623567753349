var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-combobox',{attrs:{"items":_vm.items,"chips":"","clearable":"","label":"Digitare parole di ricerca libera","multiple":"","prepend-icon":"mdi-magnify","outlined":""},on:{"change":_vm.search},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","label":""},on:{"click":select,"click:close":function($event){return _vm.removeSearchItem(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}}),_c('v-card-text',[(_vm.nOfRetrievedAssets)?_c('span',[_vm._v("Trovati "+_vm._s(_vm.nOfRetrievedAssets)+" beni")]):_vm._e(),(_vm.nOfRetrievedAssets > 0)?_c('v-data-table',{attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.retrievedAssets,"item-key":"ID","search":_vm.subsearch,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Beni per pagina',
        itemsPerPageAllText: 'Tutti i beni',
        itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
        pageText: '',
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Beni per pagina'"},model:{value:(_vm.subsearch),callback:function ($$v) {_vm.subsearch=$$v},expression:"subsearch"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.Pictures.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-camera")])]}}],null,true)},[_c('v-card',[_c('v-img',{staticClass:"ma-2",attrs:{"height":"300","max-width":"500","contain":"","src":_vm.imageUrl(item.Pictures[0].id)}})],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.selectAsset(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-arrow-right-bold-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Seleziona")])])]}},{key:"item.ID",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.DT_BUONO_SCARICO ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.ID)+" ")])]}},{key:"item.MARCA",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.DT_BUONO_SCARICO ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.MARCA)+" ")])]}},{key:"item.DS_BENE",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.DT_BUONO_SCARICO ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.DS_BENE)+" ")])]}},{key:"item.DT_BUONO_CARICO",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.DT_BUONO_SCARICO ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.DT_BUONO_CARICO)+" ")])]}},{key:"item.CURRENT_POSITION",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.DT_BUONO_SCARICO ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.CURRENT_POSITION)+" ")])]}}],null,false,153535542)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }