<template>
  <v-container>
    <v-text-field
      label="Scannerizzare o digitare vecchio codice inventario"
      ref="myinput"
      v-model="oldInventarioCode"
      @keydown.enter="searchOld"
      prepend-icon="mdi-magnify"
      :autofocus="true"
      outlined
    ></v-text-field>
  </v-container>
</template>


<script>
// @ is an alias to /src


export default {
  name: "OldSearch",

  data: () => ({ 

    oldInventarioCode: null,
    selectedAsset: {},

  }),

  methods: {

      searchOld() {
      let type = this.oldInventarioCode.charAt(0);
      let cdc = Number(this.oldInventarioCode.charAt(1));
      let inv = Number(this.oldInventarioCode.substring(2));
      // console.log(type, cdc, inv);

      // console.log(type !== "B", Number.isInteger(cdc), Number.isInteger(inv));
      if (type !== "B" || !Number.isInteger(cdc) || !Number.isInteger(inv)) {
        this.result = this.oldInventarioCode + ": invalid";
        this.oldInventarioCode = '';
      } else {
        this.result = "valid";

      this.selectedAsset.type = type;
      this.selectedAsset.cdc = cdc;
      this.selectedAsset.inventario = inv;

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/old/" + inv + '/' + cdc, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.oldInventarioCode = '';
          //console.log(response.data);
          
          // The asset was found in the old PATRIMONIO -> it's required to merge the info with the new one
          
          // PRINT OLD INFOS
          //console.log('SN old: ' + response.data.old.numeroserie);
          //console.log('Descrizione old: ' + response.data.old.descrizione);
          //console.log('Note old: ' + response.data.old.notevarie);
          //console.log('Costruttore old: ' + response.data.old.costruttore);
          //console.log('Modello old: ' + response.data.old.modello);
          //console.log('Fine Garanzia old: ' + response.data.old.scadenzagaranzia);

          // PRINT NEW INFOS
          //console.log('SN new: ' + response.data.new.NUMERO_SERIALE);
          //console.log('Descrizione new: ' +response.data.new.DS_BENE);
          //console.log('Note new: ' +response.data.new.NOTE);
          //console.log('Costruttore new: ' + response.data.new.MARCA);
          //console.log('Fine Garanzia new: ' +response.data.new.DT_GARANZIA_A);
          //console.log(response.data.new.);

          // Update SN if missing in the new one (UGOV)
          if(response.data.new.NUMERO_SERIALE == null && response.data.old.numeroserie ){
            response.data.new.NUMERO_SERIALE = response.data.old.numeroserie;
          }

          // // Update Description if missing in the new one (UGOV)
          if(response.data.new.DS_BENE == null && response.data.old.descrizione ){
            response.data.new.DS_BENE = response.data.old.descrizione + ' ' + response.data.old.modello;
          }

          // // Update Note if missing in the new one (UGOV)
          if(response.data.new.NOTE == null && response.data.old.notevarie ){
            response.data.new.NOTE = response.data.old.notevarie;
          }

          // // Update  if missing in the new one (UGOV)
          if(response.data.new.MARCA == null && response.data.old.costruttore ){
            response.data.new.MARCA = response.data.old.costruttore;
          }

          // // Update  if missing in the new one (UGOV)
          if(response.data.new.DT_GARANZIA_A == null && response.data.old.scadenzagaranzia ){
            response.data.new.DT_GARANZIA_A = response.data.old.scadenzagaranzia;
          }

          // Emit the new asset updated with old info where present
          this.$emit('searchFound', response.data.new);
        })
        .catch((error) => {

          this.oldInventarioCode = '';
          console.log(error);
          this.$emit('searchError');
        });
      }
    },

  },
}

</script>