<template>
  <v-container>
    <v-combobox
      v-model="chips"
      :items="items"
      @change="search"
      chips
      clearable
      label="Digitare parole di ricerca libera"
      multiple
      prepend-icon="mdi-magnify"
      outlined
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="removeSearchItem(item)"
          label
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-combobox>

    <v-card-text>
      <span v-if="nOfRetrievedAssets"
        >Trovati {{ nOfRetrievedAssets }} beni</span
      >
      <v-data-table
        v-if="nOfRetrievedAssets > 0"
        elevation="10"
        :headers="headers"
        :items="retrievedAssets"
        item-key="ID"
        :search="subsearch"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Beni per pagina',
          itemsPerPageAllText: 'Tutti i beni',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="subsearch"
            label="Filtra..."
            class="mx-4"
            itemsPerPageText="'Beni per pagina'"
          />
        </template>
        <template v-slot:no-data>
          <v-sheet> Nessun bene </v-sheet>
        </template>
        <template v-slot:no-results>
          <v-sheet> Nessun bene </v-sheet>
        </template>
        <template v-slot:item.id="{ item }">
          <v-tooltip v-if="item.Pictures.length > 0" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">mdi-camera</v-icon>
            </template>
            <v-card>
              <v-img
                class="ma-2"
                height="300"
                max-width="500"
                contain
                :src="imageUrl(item.Pictures[0].id)"
              >
              </v-img>
            </v-card>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="selectAsset(item)"
              >
                <v-icon large> mdi-arrow-right-bold-outline </v-icon>
              </v-btn>
            </template>
            <span>Seleziona</span>
          </v-tooltip>
        </template>
        <template v-slot:item.ID="{ item }">
          <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
            {{ item.ID }}
          </span>
        </template>
        <template v-slot:item.MARCA="{ item }">
          <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
            {{ item.MARCA }}
          </span>
        </template>
        <template v-slot:item.DS_BENE="{ item }">
          <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
            {{ item.DS_BENE }}
          </span>
        </template>
        <template v-slot:item.DT_BUONO_CARICO="{ item }">
          <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
            {{ item.DT_BUONO_CARICO }}
          </span>
        </template>
        <!-- <template v-slot:item.OWNER="{ item }">
            <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
              {{ extractFullUser(item) }}
            </span>
          </template> -->
        <template v-slot:item.CURRENT_POSITION="{ item }">
          <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
            {{ item.CURRENT_POSITION }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>


<script>
export default {
  name: "GlobalSearch",

  data: () => ({
    chips: [],
    items: ["PC", "LAPTOP", "SERVER", "TABLET", "MONITOR", "PRINTER"],
    retrievedAssets: null,
    nOfRetrievedAssets: null,
    subsearch: "",

    headers: [
      {
        text: "Inventario",
        align: "start",
        sortable: false,
        value: "ID",
      },
      {
        text: "Marca",
        align: "start",
        sortable: true,
        value: "MARCA",
      },
      {
        text: "Descrizione",
        align: "start",
        sortable: true,
        value: "DS_BENE",
      },
      {
        text: "Carico",
        align: "start",
        sortable: true,
        value: "DT_BUONO_CARICO",
      },
      {
        text: "Assegnatario",
        align: "start",
        sortable: false,
        value: "OWNER",
      },
      {
        text: "Locazione",
        align: "start",
        sortable: false,
        value: "CURRENT_POSITION",
      },
      {
        text: "",
        align: "end",
        sortable: false,
        value: "id",
      },
    ],
  }),

  methods: {
    imageUrl(id) {
      return process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + id;
    },
    search() {
      //console.log(this.chips);

      let searchString = "";
      for (let c of this.chips) {
        searchString += c + " ";
      }

      let body = {
        search: searchString,
      };

      if (searchString != "") {
        //console.log(searchObj);
        const headers = {
          authorization: "Bearer " + this.$store.state.authJwt,
        };
        this.$http
          .post(
            process.env.VUE_APP_PATRIMONIO_API + "/assets/globalsearch",
            body,
            { headers }
          )
          .then((response) => {
            console.log(response);

            if (response.data.numAssets > 0) {
              this.nOfRetrievedAssets = response.data.numAssets;
              this.retrievedAssets = response.data.assets;

              console.log(this.nOfRetrievedAssets);
              console.log(this.retrievedAssets);
            } else {
              this.$emit("searchError");
            }
          })
          .catch((error) => {
            // handle error
            console.log(error.response.data);
            this.$emit("searchError");
          });
      }
    },

    removeSearchItem(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
      // Reset the search
      this.nOfRetrievedAssets = null;
      this.retrievedAssets = null;
      this.search();
    },

    selectAsset(item) {

      console.log(item);
      this.$emit("searchFound", item);
    },
  },

  created: function () {
    // Fetch scanAssets and their reports
    this.search();
  },
};
</script>
