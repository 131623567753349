<template>
  <v-card elevation="12" min-height="600">
    <v-card-title >
      Ricerca dei beni nella stanza:
      <div class="ml-3" v-if="roomSelected"> {{roomSelected.ID}} - ({{roomSelected.LOCAL_NAME}}) <v-icon color="error" @click="roomSelected=null">mdi-close-circle</v-icon></div>
    </v-card-title>
    <br />
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      :multi-line="true"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-btn v-if="!roomSelected" color="primary" @click="selectRoom">
        Seleziona la stanza per ricerca beni
      </v-btn>
      <v-row v-if="isSelectingRoom">
        <RoomsDatatable @roomClick="roomSelection"> </RoomsDatatable>
      </v-row>
    </v-container>

    <OldSearch
      v-if="!assetSelected"
      @searchError="showSearchError"
      @searchFound="searchFound"
    ></OldSearch>
    <GlobalSearch
      v-show="!assetSelected"
      @searchError="showSearchError"
      @searchFound="searchFound"
    ></GlobalSearch>

    <v-card-title v-if="assetSelected">Modifica informazioni bene</v-card-title>
    <ReinsertInfoAsset
      v-if="assetSelected"
      :asset="assetSelected"
      @back="backPressed"
      @save="savePressed"
    >
    </ReinsertInfoAsset>
  </v-card>
</template>

<script>
// @ is an alias to /src

import OldSearch from "../../components/prima_ricognizione/OldSearch.vue";
import GlobalSearch from "../../components/prima_ricognizione/GlobalSearch.vue";
import ReinsertInfoAsset from "../../components/prima_ricognizione/ReinsertInfoAsset.vue";

import RoomsDatatable from "../../components/RoomsDatatable.vue";

export default {
  name: "FirstRecognition",

  components: {
    OldSearch,
    GlobalSearch,
    ReinsertInfoAsset,
    RoomsDatatable,
  },

  data: () => ({
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    timeout: -1,

    isSelectingRoom: false,
    roomSelected: null,
    assetSelected: null,
  }),

  methods: {
    selectRoom() {
      this.isSelectingRoom = true;
    },

    roomSelection(room) {
      console.log(room);
      this.roomSelected = room;
      this.isSelectingRoom = false;
    },

    // Hide the modify ui and show again search ui
    backPressed() {
      console.log("back");
      this.assetSelected = null;
    },

    savePressed(asset) {
      console.log("save" + asset);
      //TODO: SAVE IT
      this.assetSelected = null;

      this.permSnackbar = true;
      this.snackbarColor = "success";
      this.errorText = "Dati del bene salvati correttamente!";
      this.timeout = 5000;
    },

    showSearchError() {
      this.permSnackbar = true;
      this.snackbarColor = "error";
      this.errorText =
        "Ricerca fallita: nessun bene trovato oppure backend non funzionante! Indagare...";
      this.timeout = 5000;
    },

    searchFound(result) {
      //console.log("Bene trovato ;)");
      //console.log(result);
      this.assetSelected = result;
    },
  },

  created: function () {},

  beforeDestroy: function () {},
};
</script>