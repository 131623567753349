<template>
  <v-container>
    <v-card-subtitle class="pa-3 orange--text" v-if="assetModifiable">
      <strong>Questo bene risulta scaricato!</strong>
    </v-card-subtitle>
    <v-card-subtitle class="pa-3 green--text" v-else>
      <strong>Bene presente in UGOV non scaricato!</strong>
    </v-card-subtitle>

    <v-card-text class="pa-3">
      <v-row>
        <v-text-field
          label="Descrizione Bene:"
          v-model="asset.DS_BENE"
          :autofocus="true"
          outlined
          :disabled="!assetModifiable"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          label="Numero Seriale:"
          v-model="asset.NUMERO_SERIALE"
          outlined
          :disabled="!assetModifiable"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          label="Marca:"
          v-model="asset.MARCA"
          outlined
          :disabled="!assetModifiable"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          label="Note:"
          v-model="asset.NOTE"
          outlined
          :disabled="!assetModifiable"
        ></v-text-field>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="3" class="pa-3">
          <v-combobox
            v-model="selectedCategory"
            :items="categoryItems"
            @change="updateAssetTechType(selectedCategory)"
            label="Scegli la categoria del bene"
            outlined
            dense
          ></v-combobox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="techDataPermitted() && asset.TECH_TYPE">
      <v-row v-for="(value, field) in asset" :key="field">
        <v-row v-if="techData(field) && labelMappingAsset[field].visible" align="end">
          <v-spacer></v-spacer>
          <v-col cols="3" class="text-right">{{ labelMappingAsset[field].label != "" ? labelMappingAsset[field].label : field }}</v-col>
          <v-col cols="8">
            <v-text-field
              v-if="techData(field)"
              v-model="asset[field]"
              :value="value"
              placeholder="valore"
              hide-details="auto"
            ></v-text-field>
            <div v-else-if="field === 'VALORE_CONVENZIONALE'">
              &euro; {{ value }}
            </div>
            <div v-else-if="field === 'LAST_SEEN'">
              {{ formatDate(value) }}
            </div>
            <div v-else>
              {{ value }}
            </div>
          </v-col>
          <v-col cols="1"> </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-row class="ma-3" align="center" justify="space-around">
        <v-btn text outlined rounded @click="back">
          <v-icon>mdi-arrow-left-bold</v-icon>
          INDIETRO
        </v-btn>

        <v-btn text color="success" outlined rounded @click="save">
          <v-icon>mdi-content-save-edit</v-icon>
          SALVA
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-container>
</template>


<script>
// @ is an alias to /src

import { mapState } from "vuex";

export default {
  name: "ReinsertInfoAsset",

  props: {
    asset: Object,
  },

  data: () => ({
    assetModifiable: false,
    selectedCategory: "",
    categoryItems: ["PC", "LAPTOP", "SERVER", "TABLET", "MONITOR", "PRINTER"],
  }),

  computed: {
    ...mapState(["labelMappingAsset"]),
  },

  methods: {
    back() {
      this.$emit("back");
    },

    save() {
      this.$emit("save", this.asset);
    },
    // niceLabel(field){
    //   console.log(field);
    //   console.log(this.labelMappingAsset);
    //   console.log(this.labelMappingAsset["DS_BENE"].label)
    //   let test = String(field);
    //   return this.labelMappingAsset[test].label;
    // },
    techDataPermitted() {
      return this.asset.CD_CATEG_GRUPPO.startsWith("I.M.03");
    },
    techData(field) {
      return (
        this.asset.TECH_TYPE && field.startsWith("TECH_" + this.asset.TECH_TYPE)
      );
    },
    autofillAssetCategory() {
      // Try to autofill the asset category
      // Else if omitted because for example one monitor is classifid with personal computer, this way the monitor label is assigned
      let catString = this.asset.DS_CATEG_GRUPPO.toLowerCase();
      let descString = this.asset.DS_BENE.toLowerCase();

      if (
        catString.includes("personal computer") ||
        descString.includes("personal computer")
      ) {
        this.selectedCategory = "PC";
      }
      if (
        catString.includes("server") ||
        descString.includes("server")
      ) {
        this.selectedCategory = "SERVER";
      }
      if (
        catString.includes("stampante") ||
        descString.includes("stampante") ||
        descString.includes("printer")
      ) {
        this.selectedCategory = "PRINTER";
      }
      if (
        catString.includes("notebook") ||
        descString.includes("notebook") ||
        descString.includes("portatile") ||
        descString.includes("laptop")
      ) {
        this.selectedCategory = "LAPTOP";
      }
      if (
        catString.includes("monitor") ||
        descString.includes("monitor") ||
        descString.includes("schermo")
      ) {
        this.selectedCategory = "MONITOR";
      }
      if (
        catString.includes("palmare") ||
        descString.includes("tablet") ||
        descString.includes("palmare") ||
        descString.includes("ipad")
      ) {
        this.selectedCategory = "TABLET";
      }

      // UPDATE the asset tech_type with this extimated one
      this.updateAssetTechType(this.selectedCategory);

      // Autofill the serial number of the specified type
      this.asset["TECH_" + this.asset.TECH_TYPE + "_SERIALNUM"] =
        this.asset.NUMERO_SERIALE;
    },

    updateAssetTechType(type) {
      // RESET the serial number of the old specified type
      this.asset["TECH_" + this.asset.TECH_TYPE + "_SERIALNUM"] = null;

      // UPDATE the asset tech_type with the specified value;
      this.asset.TECH_TYPE = type;
      // Autofill the serial number of the specified type
      this.asset["TECH_" + this.asset.TECH_TYPE + "_SERIALNUM"] =
        this.asset.NUMERO_SERIALE;
    },
  },

  created: function () {
    // Check if this asset was discharged! If yes then a new asset must be reloaded into UGOV and we give the possibility to change the asset info
    if (this.asset.DT_BUONO_SCARICO) {
      this.assetModifiable = true;
    } else {
      this.assetModifiable = false;
    }

    // Autofill asset category
    this.autofillAssetCategory();
  },
};
</script>